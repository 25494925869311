import React from "react"
import Accordion from "./accordion"
import { parseHTML } from "../../../helpers/parseHTML"
import { Helmet } from 'react-helmet';
import { stripHtml } from "string-strip-html";

export default function AccordionModule({ title, accordions }) {

  let schemaList = [];

  for (let i = 0; i < accordions.length; i++) {
    const accordion = accordions[i];
    schemaList.push({
      "@type": "Question",
      "name": `${accordion.title}`,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": `${stripHtml(accordion.content).result}`
      }
    })
  }

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": ${JSON.stringify(schemaList)}
            }
          `}
        </script>
      </Helmet>
      <div className={`accordions`}>
        {title ?
          <h2 className="accordions__title">{title}</h2>
          : null
        }
        <ul className="accordions__list">
          {accordions.map((accordion, key) => {
            return (
              <Accordion
                index={accordion.id}
                title={accordion.title}
                key={`key-${key}`}
                accordionTitleTag={title}
              >
                <div className="accordions__content rte">
                  {parseHTML(accordion.content)}
                </div>
              </Accordion>
            )
          })}
        </ul>
      </div>
    </>
  )
}
