import React from "react"
import WhiteLogo from "../../svg/hc-logo-white.svg"
import Facebook from "../../svg/facebook-logo.svg"
import Email from "../../svg/email.svg"
import AnimatedLink from "../global/animatedLink"
import dayjs from "dayjs"
import { parseHTML } from "./../../helpers/parseHTML"
import animateScrollTo from "animated-scroll-to"
import { useStaticQuery, graphql } from "gatsby"

export default function Footer({ home }) {
  const { data, nav } = footerData()

  function handleHIW(e) {
    e.preventDefault()
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const offset =
      document.querySelector(".homepage-explainer").getBoundingClientRect()
        .top + scrollTop
    // When top nav hides while scrolling down, sets the correct offset
    animateScrollTo(offset, { speed: 1500 })
  }

  return (
    <footer className={`footer`}>
      <div className="outer-container">
        <div className="inner-container">
          <div className="footer__content">
            <div className={`footer__content-column`}>
              <WhiteLogo className={`footer__logo`} />
              <div className={`footer__about`}>{parseHTML(data.aboutText)}</div>
            </div>
            <div className={`footer__content-column`}>
              <p className={`footer__content-column-title`}>Site</p>
              {home !== undefined ? (
                <a
                  className={`footer__content-column-item`}
                  onClick={handleHIW}
                >
                  How it works
                </a>
              ) : (
                <AnimatedLink
                  className={`footer__content-column-item`}
                  to={"/?scrollto"}
                  logEvent={`Footer - How it works link`}
                >
                  How it works
                </AnimatedLink>
              )}
              <br />
              {nav.nav.map((nav, index) => {
                return (
                  <React.Fragment key={index}>
                    <AnimatedLink
                      className={`footer__content-column-item`}
                      to={nav.linkUrl}
                      logEvent={`Footer - ${nav.linkTitle} link`}
                    >
                      {nav.linkTitle}
                    </AnimatedLink>
                    <br />
                  </React.Fragment>
                )
              })}
            </div>
            <div className={`footer__content-column`}>
              <p className={`footer__content-column-title`}>Legal</p>
              {data.extraLinks.map((link, index) => {
                return (
                  <React.Fragment key={index}>
                    <AnimatedLink
                      className={`footer__content-column-item`}
                      to={link.linkUrl}
                      logEvent={`Footer - ${link.linkTitle} link`}
                    >
                      {link.linkTitle}
                    </AnimatedLink>
                    <br />
                  </React.Fragment>
                )
              })}
            </div>
            <div className={`footer__content-column`}>
              <p className={`footer__content-column-title`}>Social</p>
              <a
                href={`mailto:${data.contactEmail}`}
                className={`footer__content-column-item`}
                target="_blank"
              >
                <Email /> Contact us
              </a>
              <br/>
              <a
                href={`https://www.facebook.com/helpfulchat`}
                className={`footer__content-column-item`}
                target="_blank"
              >
                <Facebook /> Facebook
              </a>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div className="footer__subfooter">
        <div className="outer-container">
          <div className="inner-container">
            © {dayjs().format("YYYY")} Helpful Chat
          </div>
        </div>
      </div>
    </footer>
  )
}

const footerData = () => {
  const data = useStaticQuery(graphql`
    query {
      data: datoCmsFooter {
        aboutText
        contactEmail
        extraLinks {
          linkTitle
          linkUrl
        }
        socialMedia {
          socialMediaType
          socialMediaUrl
        }
      }
      nav: datoCmsNav {
        nav {
          linkTitle
          linkUrl
        }
      }
    }
  `)
  return data
}
