import React, { useRef } from 'react'
import { bind } from 'size-sensor'
import firebase from "gatsby-plugin-firebase";

// @ts-ignore
import ArrowDownBlue from '../../../svg/arrow-down-blue.svg'

const Accordion = ({title, index, children, openFirst, hasParentTitle}) => {
    // build random ID to separate accordion
  let unbind = null;
  const accordionRef = useRef(null),
        accordionTitleRef = useRef(null);

  const triggerAccordion = (title) => {
    let transitionPixelsPerSecond = 2000,
        transitionExtraBuffer = 0.3,
        accordionContent = accordionRef.current,
        accordionIconEl = accordionTitleRef.current;

    if (accordionContent) {
      let elHeight = accordionContent.scrollHeight + 60;

      if (accordionContent.classList.contains('active')) {
        accordionContent.style.maxHeight = 0  + "px";
        accordionContent.style.transition = `${(elHeight / transitionPixelsPerSecond) + transitionExtraBuffer}s all`;
        accordionContent.classList.remove('active');
        accordionIconEl.classList.remove('active');
        if (unbind) unbind()
      } else {
        accordionContent.style.transition = `${(elHeight / transitionPixelsPerSecond) + transitionExtraBuffer}s all`;
        accordionContent.style.maxHeight = elHeight + "px";
        accordionContent.classList.add('active');
        accordionIconEl.classList.add('active');
        unbind = bind(accordionContent, () => {
          updateMaxHeight();
        });
        firebase.analytics().logEvent(`Content - faq accordion click - ${title}`)
      }
    }
  }

  const updateMaxHeight = () => {
    let accordionContent = accordionRef.current;

    if (accordionContent && accordionContent.classList.contains('active')) {
      let elHeight = accordionContent.scrollHeight + 60;
      accordionContent.style.maxHeight = elHeight + "px";
    }
  }

  if (openFirst) {
    setTimeout(() => {
      let accordionContent = accordionRef.current;
      if (accordionContent && !accordionContent.classList.contains('active')) {
        triggerAccordion(title)
      }
    }, 500);
  }

  return (
    <li 
      className="accordion"
      key={`accordion-${index}`}
    >
      <button 
        className="accordion__toggle"
        onClick={() => {
          triggerAccordion(title)
        }}
        aria-expanded={false}
      >
        <span 
          className="accordion__toggle-title"
          ref={accordionTitleRef}
        >
          {hasParentTitle ?
            <h3>{title}</h3>
          :
            <h2>{title}</h2>
          }
          <ArrowDownBlue  className={`accordion__icon`} />
        </span>
      </button>
      <div 
        className="accordion__content"
        ref={accordionRef}
      >
        {children}
      </div>
    </li>
  )
}

export default Accordion;