import React from "react"
import { parseHTML } from "../../../helpers/parseHTML"
import Img from "gatsby-image/withIEPolyfill"
import AnimatedLink from "../../global/animatedLink"
import firebase from "gatsby-plugin-firebase"

export default function CtaBlock({
  title,
  description,
  image,
  imageAlignment,
  ctaLinks,
}) {
  const logEvent = (text, url) => {
    if (firebase.analytics) {
      firebase.analytics().logEvent("Content - cta_block", {
        text: text,
        url: url,
      })
    }
  }

  return (
    <section className={`cta-block cta-block--${imageAlignment}`}>
      <>
        <div className="cta-block__content">
          <h2 className="cta-block__content-title">{title}</h2>
          {description ? (
            <div className="cta-block__content-desc">
              {parseHTML(description)}
            </div>
          ) : null}
          {ctaLinks ? ctaLinks.map(({ linkText, linkUrl, isExternalUrl }, index) => {
                return isExternalUrl ? (
                  <a
                    key={`cta-block-${index}`}
                    className="cta-block__content-link"
                    href={linkUrl}
                    onClick={logEvent(linkText, linkUrl)}
                    target="_blank"
                  >
                    {linkText}
                  </a>
                ) : (
                  <AnimatedLink
                    key={`cta-block-${index}`}
                    className="cta-block__content-link"
                    to={linkUrl}
                    onClick={logEvent(linkText, linkUrl)}
                  >
                    {linkText}
                  </AnimatedLink>
                )
              })
          : null}
        </div>
        {Img.fluid ? (
          <Img
            fluid={image.fluid}
            alt={image.alt ? image.alt : ""}
            className="cta-block__image"
          />
        ) : (
          <img
            className="cta-block__image cta-block__image--svg"
            src={image.url}
            alt={image.alt ? image.alt : ""}
          />
        )}
      </>
    </section>
  )
}
