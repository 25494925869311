import React from "react";
import AccordionsModule from '../modules/accordions/accordions-module';
import CtaBlockModule from '../modules/cta-block/cta-block';

const PageBody = ({modules}) => {
  let bodyHTML = [];

  for (let section = 0; section < modules.length; section ++) {
    let module = modules[section],
        bodyMarkup;

    if (module) {
      switch (module.__typename) {
        case 'DatoCmsAccordionList':
          bodyMarkup = (
            <AccordionsModule
              key={section}
              id={module.id}
              accordions={module.accordions}
              title={module.title}
            />
          )
          
        break;
        case 'DatoCmsCtaBlock':
          bodyMarkup = (
            <CtaBlockModule
              key={section}
              id={module.id}
              title={module.title}
              description={module.description}
              image={module.image}
              imageAlignment={module.imageAlignment}
              ctaLinks={module.ctaLinks}
            />
          )
        break;

        default:
          break;
      }
      bodyHTML.push(bodyMarkup)
    }
  }

  return bodyHTML;
}

export default PageBody;
