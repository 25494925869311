import React from 'react'
import { graphql } from 'gatsby'
import Layout from "../layouts/layout"
import SEO from "../layouts/seo"
import Nav from './../global/nav';
import Footer from './../global/footer';
import Img from 'gatsby-image/withIEPolyfill';
import { parseHTML } from './../../helpers/parseHTML';
import PageBody from '../body/page-body';
import { Helmet } from 'react-helmet';

export default function BasicPageTemplate(props) {
  let { transitionStatus, data } = props;
  let { pageData } = data;

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://helpful.chat"
              },{
                "@type": "ListItem",
                "position": 2,
                "name": "${pageData.title}",
                "item": "https://helpful.chat/${pageData.slug}"
              }]
            }
          `}
        </script>
      </Helmet>
      <SEO
        title={pageData.title} 
        description={pageData.metaDescription} 
        ogDescription={pageData.ogDescription} 
        ogImage={pageData.ogImage?.url} 
      />
      <Nav/>
      <Layout
        data={data}
        class={pageData.slug}
        transitionStatus={transitionStatus}
      >
        <div className="basic-page">
          <Img 
            fluid={pageData.backgroundImage.fluid} 
            alt={pageData.backgroundImage.alt} 
            className={`basic-page__background-image`}
          ></Img>
          <div className="outer-container">
            <div className="inner-container">
              <h1 className={`basic-page__title`}>{pageData.title}</h1>
              <div className={`basic-page__body ${pageData.isFullWidth ? 'basic-page__body--full-width' : ''}`}>
                <div className="basic-page__body-content rte">
                  {parseHTML(pageData.body)}
                </div>
                {pageData.modules.length
                  ?
                    <div className="basic-page__body-modules">
                      <PageBody
                        modules={pageData.modules}
                      />
                    </div>
                  :
                    null
                }
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    pageData: datoCmsBasicPage(slug: {eq: $slug}) {
      title 
      slug
      body
      isFullWidth
      backgroundImage {
        alt
        url
        fluid(maxWidth: 1200) {
          ...GatsbyDatoCmsFluid
        }
      }
      modules {
        ... on DatoCmsAccordionList {
          __typename
          id
          title
          accordions {
            id
            title
            content
          }
        }
        ... on DatoCmsCtaBlock {
          __typename
          id
          title
          description
          image {
            alt
            url
            fluid(maxWidth: 1200) {
              ...GatsbyDatoCmsFluid
            }
          }
          imageAlignment
          ctaLinks  {
            linkText
            linkUrl
            isExternalUrl
          }
        }
      }
      metaDescription
      ogDescription
      ogImage {
        url
      }
    }
  }
`